import { useCarroCompras } from "../stores/carrocompras";

const useCarro = useCarroCompras()

export default defineNuxtRouteMiddleware((to, from) => {
    /**
     * La funcion de este middleware es que 
     * 1. Si el usuario no tiene productos en el carrito
     * no pueda acceder a las demas rutas de compra sino comienza por /carro-compras
     * Si el usuario ya realizo la compra no podra acceder
     * a las rutas de compra
     */

    const routes_cart = [
        '/carro-compras', 
        '/formas-pago',
        '/formas-envio',
        '/datos-cliente',
        '/resumen-compra',
        '/detalle-compra'
    ]
    /**
     * 1. Si no se viene de los pasos de carro de compras se reinicia el proceso
     */
    
    if((!routes_cart.includes(from.path) || useCarro.carrito.length == 0) && to.path != '/carro-compras') {
        
        // useCarro.formas reset forma de pago
        useCarro.vars.pasos_carro = 1
        useCarro.vars.radio_forma_pago = 0
        useCarro.vars.radio_forma_pago_transferencia = 0
        
        
        // useCarro.forma_pago = null
        return navigateTo('/carro-compras')
    }
})
